import React, { Component } from 'react';
import Loading from 'Services/Loading';
import { notifyError } from 'Services/Notificacoes';
import Folder from 'views/Pages/Assets/Folder';
import { assincronoInstrumentosIDS } from 'Services/Constantes';

// Instrumentos

import HistoriaDeVida from './HistoriaDeVida';
import EstilosDeLideranca from './EstilosDeLideranca';
import TiposDePersonalidade from './TiposDePersonalidade';
import ProjetosAtuais from "./ProjetosAtuais";
import LocusDeControle from './LocusDeControle';
import AutoconceitoNoTrabalho from './AutoconceitoNoTrabalho';
import AspectosDeIdentidade from './AspectosDeIdentidade';
import ColetaDeFeedback from './ColetaDeFeedback';
import ArteDaArticulacao from './ArteDaArticulacao';
import EstilosDeComunicacao from 'views/Pages/Assets/ModalResultado/Resultados/EstilosDeComunicacao';
import AncorasDaCarreira from 'views/Pages/Assets/ModalResultado/Resultados/AncoraDeCarreira';
import EscutaAtiva from './EscutaAtiva';
import AutoEficacia from './AutoEficacia';

/**
* ### Componente de Resultados da Sociometria
*
* /**
* @author Gustavo Carvalho Silva
* @since 19/11/2020
* @props
* - instrumentos
* - idProjeto
* - idParticipante
*
* @desc Monta os folders dos instrumentos através dos ids recebidos na prop instrumentos
*/
export default class ResultadoAssessments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      instrumentos: [],
    };
  };

  getInstrumentosAssincrono() {
    const { instrumentos } = this.props;
    this.setState({ loading: true });
    try {
      this.setState({
        instrumentos,
        loading: false,
      });
    } catch (err) {
      notifyError('Algo deu errado, por favor recarregue a página!');
    }
  }

  componentDidMount() {
    this.getInstrumentosAssincrono();
  }

  render() {
    const {
      instrumentos
    } = this.state;
    const {
      idParticipante,
      idProjeto,
      idAssincrono,
    } = this.props;

    return (
      <div>
        {
          this.state.loading
            ?
            <Loading />
            : instrumentos.length ?
            <>
              {
                instrumentos.map(instrumento => (
                  <React.Fragment key={instrumento.idInstrumento}>
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.HistoriaDeVida &&
                      <Folder
                        key={instrumento.idInstrumento}
                        show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                        nome={"Análise Argumentativa"}
                        >
                          <HistoriaDeVida
                            nomeParticipante={this.props.nomeParticipante}
                            idParticipante={idParticipante}
                            idProjeto={idProjeto}
                            idAssincrono={idAssincrono}
                            idInstrumento={instrumento.idInstrumento}
                            participantViewResults={this.props.participantViewResults}
                          />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.EstilosDeLideranca &&
                      <Folder
                        key={instrumento.idInstrumento}
                        show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                        nome={"Estilos de Liderança"}
                        >
                          <EstilosDeLideranca
                            isConsultant={this.props.isConsultant}
                            idParticipante={idParticipante}
                            idProjeto={idProjeto}
                            idAssincrono={idAssincrono}
                            idInstrumento={instrumento.idInstrumento}
                            participantViewResults={this.props.participantViewResults}
                          />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.ProjetosAtuais &&
                      <Folder
                        key={instrumento.idInstrumento}
                        show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                        nome={"Projetos Atuais"}
                        >
                          <ProjetosAtuais
                            idParticipante={idParticipante}
                            idProjeto={idProjeto}
                            idAssincrono={idAssincrono}
                            idInstrumento={instrumento.idInstrumento}
                            participantViewResults={this.props.participantViewResults}
                          />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.TiposDePersonalidade &&
                      <Folder
                        key={instrumento.idInstrumento}
                        show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                        nome={"Tipos de Personalidade"}
                        >
                        <TiposDePersonalidade
                          idParticipante={idParticipante}
                          idProjeto={idProjeto}
                          idAssincrono={idAssincrono}
                          idInstrumento={instrumento.idInstrumento}
                          participantViewResults={this.props.participantViewResults}
                        />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.EscutaAtiva &&
                      <Folder
                        key={instrumento.idInstrumento}
                        show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                        nome={"Escuta Ativa"}
                        >
                        <EscutaAtiva
                          idParticipante={idParticipante}
                          idProjeto={idProjeto}
                          idAssincrono={idAssincrono}
                          idInstrumento={instrumento.idInstrumento}
                          participantViewResults={this.props.participantViewResults}
                        />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.Autoeficacia &&
                      <Folder
                        key={instrumento.idInstrumento}
                        show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                        nome={"Autoeficácia"}
                        >
                        <AutoEficacia
                          idParticipante={idParticipante}
                          idProjeto={idProjeto}
                          idAssincrono={idAssincrono}
                          idInstrumento={instrumento.idInstrumento}
                          participantViewResults={this.props.participantViewResults}
                        />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.LocusdeControle &&
                      <Folder
                        key={instrumento.idInstrumento}
                        show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                        nome={"Locus de Controle"}
                        >
                        <LocusDeControle
                          idParticipante={idParticipante}
                          idProjeto={idProjeto}
                          idAssincrono={idAssincrono}
                          idInstrumento={instrumento.idInstrumento}
                          participantViewResults={this.props.participantViewResults}
                        />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.ArteDaArticulacao &&
                      <Folder
                        key={instrumento.idInstrumento}
                        show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                        nome={"Arte da Articulação"}
                        >
                        <ArteDaArticulacao
                          idParticipante={idParticipante}
                          idProjeto={idProjeto}
                          idAssincrono={idAssincrono}
                          idInstrumento={instrumento.idInstrumento}
                          participantViewResults={this.props.participantViewResults}
                        />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.AutoconceitoNoTrabalho &&
                      <Folder
                        key={instrumento.idInstrumento}
                        show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                        nome={"Autoconceito no Trabalho"}
                        >
                        <AutoconceitoNoTrabalho
                          idParticipante={idParticipante}
                          idProjeto={idProjeto}
                          idAssincrono={idAssincrono}
                          idInstrumento={instrumento.idInstrumento}
                          participantViewResults={this.props.participantViewResults}
                        />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.AspectosDeIdentidade &&
                      <Folder
                      key={instrumento.idInstrumento}
                      show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                      nome={"Aspectos de Identidade"}
                      >
                        <AspectosDeIdentidade
                          idParticipante={idParticipante}
                          idProjeto={idProjeto}
                          idAssincrono={idAssincrono}
                          idInstrumento={instrumento.idInstrumento}
                          participantViewResults={this.props.participantViewResults}
                        />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.ColetaDeFeedback &&
                      <Folder
                      key={instrumento.idInstrumento}
                      show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                      nome={"Coleta de Feedback"}
                      >
                        <ColetaDeFeedback
                          isConsultant={this.props.isConsultant}
                          nomeParticipante={this.props.nomeParticipante}
                          idParticipante={idParticipante}
                          idProjeto={idProjeto}
                          idAssincrono={idAssincrono}
                          idInstrumento={instrumento.idInstrumento}
                          participantViewResults={this.props.participantViewResults}
                        />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.EstiloDeComunicacao &&
                      <Folder
                        key={instrumento.idInstrumento}
                        show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                        nome={"Estilo de Comunicação"}
                      >
                        <EstilosDeComunicacao
                          resultado={{
                            respostas: [{}],
                            respondentes: [],
                            nome: ''
                          }}
                          idParticipante={idParticipante}
                          nomeParticipante={this.props.nomeParticipante}
                          idProjeto={idProjeto}
                          idAssincrono={idAssincrono}
                          idInstrumento={instrumento.idInstrumento}
                          participantViewResults={this.props.participantViewResults}
                        />
                      </Folder>
                    }
                    {
                      instrumento.idInstrumento == assincronoInstrumentosIDS.AncorasDaCarreira &&
                      <Folder
                        key={instrumento.idInstrumento}
                        show={(this.props.idAssincronoInstrumento && this.props.idAssincronoInstrumento == instrumento.idInstrumento)}
                        nome={"Âncoras da carreira"}
                      >
                        <AncorasDaCarreira
                          resultado={{
                            respostas: [{}],
                            respondentes: [],
                            nome: ''
                          }}
                          idParticipante={idParticipante}
                          idProjeto={idProjeto}
                          idAssincrono={idAssincrono}
                          idInstrumento={instrumento.idInstrumento}
                          participantViewResults={this.props.participantViewResults}
                        />
                      </Folder>
                    }
                    </React.Fragment>
                ))
              }
            </> :
            <div style={{ color: '#f00', fontSize: 12 }}>
              Não há instrumentos com resultados para serem mostrados
            </div>
        }
      </div>
    )
  }
}