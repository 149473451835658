import React from "react";
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import Messages from 'static/Messages';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
} from "reactstrap";
import moment from "moment";
import { FaRegQuestionCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tippy';
import _ from 'lodash';

import {
  getParticipantesAssessmentAcompanhamento,
} from "../../Modulos/requisicoes";
import { notifyError, notifySuccess } from "../../../../Services/Notificacoes";
import { ButtonSave, ButtonCancel } from "../../../Pages/Assets/Assets";
import api from "../../../../Services/api";
import BlueBanner from "../../../Pages/Assets/ModalResultado/BlueBanner";
import CustomSelect from "../../../../assets/CustomSelect";
import "./modalAcompanhamento.css";
import * as ModalAcompanhamentoService from './services';
import apiCalls from "config/apiCalls";
import { Subtitle } from "assets/Typography";
import { capitalizeFirstLetterEachWord } from "utils/masks";
import { Constantes } from "Services/Constantes";
import { RedText } from './styles.jsx';
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';
import calcularSomenteDataFim from 'views/Pages/Modulos/Assessment/calcularSomenteDataFim';

import saveResults from './utils/chartFunctions';

export const ButtonText = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  color: #02264a;
  cursor: pointer;
`;

const Span = styled.span`
  font-size: 12px;
`

export default class Perception extends React.Component {
  state = {
    sessao: null,
    noResultsFound: false,
    percepcao: {
      usuario: null,
      assessment: null,
      userId: null,
      idUsuario: "",
      atributosProntidao: {
        conhecimentoAcumulado: null,
        nivelEntrega: null,
        determinacao: null,
        disposicao: null,
        familiaridadeNiveisSuperiores: null,
        prontidao: null,
      },
      atributosPotencial: {
        estrategiasEnfrentamento: null,
        agendaMudanca: null,
        nivelAgregacaoValor: null,
        buscasReferenciasProfissionais: null,
        construcaoVinculos: null,
        transitoDiferentesNiveis: null,
      },
      preditivo: {
        cargoAtual: {
          prontoCargo: true,
          mesesPermanencia: '',
        },
        umCargoAcima: {
          prontoCargo: false,
          mesesPermanencia: '',
        },
      },
      historicoDesempenho: {
        desempenho: null,
        riscoPerda: null,
      },
    },
    cienciaCamposVazios: false,
    radioControl: false,
    radioControlDois: null,
    radioControlClick: null,
    radioControlClick2: null,
    nextCargo: 'PRÓXIMO CARGO',
    cargoAtual: 'CARGO ATUAL',
    user: {},
    loading: true,
    perceptionLoading: false,
    modalConfirmar: false,
    hierarchies: [],
    infoParticipantes: [],
    cliente: [],
    participante: {
      cargo: '',
      id: '',
      participantId: ''
    },
    optionsDesempenho: [
      { valor: 1, label: "Abaixo do esperado" },
      { valor: 2, label: "Dentro do esperado" },
      { valor: 3, label: "Supera o esperado" },
    ],
    optionsRisco: [
      { valor: 1, label: "Alto risco" },
      { valor: 2, label: "Atenção" },
      { valor: 3, label: "Baixo risco de perda" },
    ],
    error: false,
  };

  handleError(error, msg = Messages.Geral.msgOperationError) {
    try {
      let notf = msg;
      if (error.response && error.response.data && error.response.data.msg && error.response.data.msg.includes('credits'))
        notf = 'Não foi possível executar a operação selecionada, créditos insuficientes.';

      notifyError(notf);
    } catch (error2) {
      console.error(error2);
    }
  }

  setParticipante  = (id, participantes) => {
    let dadosCliente = [];
    let participanteFound = null;
    participantes.map((participante) => {
      if (participante.id === id) {
        dadosCliente.push(participante);
        participanteFound = participante;
        this.setState({ participante, percepcao: {...this.state.percepcao, userId: participante.participantId, assessment: this.props.assessmentId, usuario: id, idUsuario: sessionStorage.getItem('user')} });
      }
    });

    this.setState({
      modalEditar: true,
      id: id,
      cliente: dadosCliente,
    });
    return participanteFound;
  };

  obterSessoes = async () => {
    try {
      let assessmentId = this.props.assessmentId;
      const projectId = sessionStorage.getItem("projeto");
      let acompanhamentoSessoes = await getParticipantesAssessmentAcompanhamento(
        assessmentId,
        'modal-percepcao'
      );
      let participantes = [];
      let nomeAssessment = "";
      let checked = [];

      let inscricoesConsultor = null;
      const participantesComSessao = [];
      const participantesSemSessao = [];

      if (acompanhamentoSessoes.length > 0) {
        acompanhamentoSessoes.forEach((dados) => {
          nomeAssessment = dados.assessment.nome;
          inscricoesConsultor = dados.assessment.inscricoesConsultor;

          if (dados.participante) {
            participantesComSessao.push(dados.participante._id);
          }

          let nomeVerdadeiro;
          if(dados.participante){
            dados.participante.projetos.forEach(el => {
              if(dados.assessment.projeto === el._idProjeto){
                nomeVerdadeiro = el.nome;
              }
            })
          }

          let userProject = {};
          if (dados.participante) {
            userProject = dados.participante.projetos
              .find(project => project._idProjeto === sessionStorage.getItem('projeto'));
          }


          participantes.push({
            nome: nomeVerdadeiro ? nomeVerdadeiro : null,
            id: dados._id,
            cargo: userProject.cargo || '',
            participantId: dados.participante && dados.participante._id || null,
          });
        });

        acompanhamentoSessoes.forEach((part) => {
          checked.push({
            id: part._id,
            checked: false,
          });
        });
      }

      let part = participantes.filter((p) => {
        return p.nome !== "";
      });

      if (part.length) {
        this.setState({
          semParticipante: false,
        });
      } else if (!part.length) {
        this.setState({
          semParticipante: true,
        });
      }
      this.setState({
        infoParticipantes: participantes,
        loading: false
      });

      return participantes;
    } catch (error) {
      console.error(error);
      notifyError('Não foi possível carregar os dados das sessões. Por favor, recarregue a página');
    }
  };

  async getUserHierarchy(hierarchies) {
    try {
      let userCargo = this.state.participante.cargo;
      const userHierarchyIndex = hierarchies.findIndex(h => (
        h.cargo.toLowerCase() === userCargo.toLowerCase())
      );

      this.setState({
        cargoAtual: userCargo || '',
        nextCargo: hierarchies[userHierarchyIndex + 1].cargo
      });
    } catch (err) {
      notifyError('Não foi possível detectar o próximo cargo do participante. Por favor, recarregue a página e tente novamente');
    }
  }

  async componentDidMount() {
    try {
      this.setState({ perceptionLoading: true });
      const sessions = await this.obterSessoes();
      const foundSession = sessions.find(p => p.participantId === this.props.participantId)

      if (foundSession) {
        this.setState({ sessao: foundSession.id })
      }

      const resP = await apiCalls.resultados.getInfoParticipante(
        sessionStorage.getItem("projeto"),
        this.props.participantId
      );

      const foundParticipant = resP && resP.data ? {
        cargo: resP.data.cargo,
        id: this.props.assessmentId,
        nome: resP.data.nome,
        participantId: resP.data._id
      } : null

      if (!foundParticipant) return this.setState({ noResultsFound: true });
      const participante = this.setParticipante(foundParticipant.id, [foundParticipant]);

      this.obterPercepcao();
      ModalAcompanhamentoService.fetchHierarchy({
        projectId: sessionStorage.getItem('projeto'),
        cargo: participante.cargo
      })
      .then(hierarchies => {

        this.setState({ hierarchies });
        return this.getUserHierarchy(hierarchies);
      })
      .then(async _ => {
        try {
          if (participante && participante.participantId) {
            this.setState({ user: resP.data });
          }
        } catch (err) {
          console.error(err);
        } finally {

        }
      })
      .catch(err => console.error(err));
    } catch (err) {
      console.error(err)
      notifyError(Messages.Geral.msgDataFetchError);
      this.setState({ error: true })
    } finally {
      this.setState({ perceptionLoading: false });
    }
  }

  obterPercepcao = async () => {
    try {
      let percepcaoObtida = await api.get(`/api/percepcoes`, {
        params: {
          assessment: this.props.assessmentId,
          userId: this.props.participantId
        },
      });

      if (percepcaoObtida.data) {
        const percepcao = percepcaoObtida.data;

        this.setState({
          percepcao: {
            ...percepcao,
            idUsuario: sessionStorage.getItem("user"),
          },
        });
      }
    } catch (err) {
      console.error("err", err);
    }
  };

  checkEmptyFields() {
    try {
      let { percepcao } = this.state;
      let emptyFields = 0;

      if (!percepcao.atributosProntidao.conhecimentoAcumulado) emptyFields++;
      if (!percepcao.atributosProntidao.nivelEntrega) emptyFields++;
      if (!percepcao.atributosProntidao.determinacao) emptyFields++;
      if (!percepcao.atributosProntidao.disposicao) emptyFields++;
      if (!percepcao.atributosProntidao.familiaridadeNiveisSuperiores)
        emptyFields++;
      if (!percepcao.atributosProntidao.prontidao) emptyFields++;
      if (!percepcao.atributosPotencial.estrategiasEnfrentamento) emptyFields++;
      if (!percepcao.atributosPotencial.agendaMudanca) emptyFields++;
      if (!percepcao.atributosPotencial.nivelAgregacaoValor) emptyFields++;
      if (!percepcao.atributosPotencial.buscasReferenciasProfissionais)
        emptyFields++;
      if (!percepcao.atributosPotencial.construcaoVinculos) emptyFields++;
      if (!percepcao.atributosPotencial.transitoDiferentesNiveis) emptyFields++;
      if (!percepcao.historicoDesempenho.desempenho) emptyFields++;
      if (!percepcao.historicoDesempenho.riscoPerda) emptyFields++;

      if (percepcao.preditivo) {
        const mesesPermanenciaAtual = Number(percepcao.preditivo.cargoAtual.mesesPermanencia);
        const mesesPermanenciaAcima = Number(percepcao.preditivo.umCargoAcima.mesesPermanencia);
        if (
          !percepcao.preditivo.cargoAtual.prontoCargo &&
          !mesesPermanenciaAtual) {

          emptyFields++
        }
        if (!mesesPermanenciaAcima) {

          emptyFields++
        }
      }


      this.setState({ emptyFields });

      return emptyFields;
    } catch (err) {
      console.error(err);
      notifyError('Não foi possível validar o formulário! Por favor, verifique os dados informados e tente novamente');
      return 1;
    }
  }

  updateAssessmentResult = async () => {
    try {
      await apiCalls.assessments.calculatePerceptionResults(
        this.props.assessmentId
      );
    } catch (error) {
      console.error(error);
      notifyError(Messages.Assessment.msgUpdateRankingError);
    }
  }

  handleSubmit = async () => {
    try {

    this.setState({ modalConfirmar: false });
    if (this.state.percepcao._id) {
      if (this.checkEmptyFields() && !this.state.cienciaCamposVazios) {
        this.setState({ modalConfirmar: true });

        return;
      }

      await api.put(
        `/api/percepcoes/${this.state.percepcao._id}`,
        {
          ...this.state.percepcao,
          idUsuario: sessionStorage.getItem("user"),
        }
      );

      if (this.state.emptyFields) {
        await api.put(`/api/sessoes/marcar-como-incompleta`, {
          sessao: this.state.sessao,
          userId: this.props.participantId
        });
      } else {
        await api.put(`/api/sessoes/marcar-como-finalizada`, {
          sessao: this.state.sessao,
          userId: this.props.participantId
        });
      }

      notifySuccess("Percepção atualizada com sucesso!");
    } else {
      if (this.checkEmptyFields() && !this.state.cienciaCamposVazios) {
        this.setState({ modalConfirmar: true });

        return;
      }

      const postResponse = await api.post(`/api/percepcoes`, {
        ...this.state.percepcao,
        idUsuario: sessionStorage.getItem("user"),
      });
      this.setState(prev => ({
        percepcao: {
          ...prev.percepcao,
          _id: postResponse.data.percepcao._id
        }
      }));

      if (this.state.emptyFields) {
        await api.put(`/api/sessoes/marcar-como-incompleta`, {
          sessao: this.state.sessao,
          userId: this.props.participantId
        });
      } else {
        await api.put(`/api/sessoes/marcar-como-finalizada`, {
          sessao: this.state.sessao,
          userId: this.props.participantId
        });
      }

      notifySuccess("Percepção adicionada com sucesso!");
      this.setState({ modalConfirmar: false });
    }

    await this.updateAssessmentResult();
    if (this.props.onSave) {
      let status;

      if (this.state.emptyFields) {
        status = 2; // incomplete perception
      } else {
        status = 4; // complete perception
      }

      await this.props.onSave({
        participantId: this.props.participantId,
        assessmentId: this.props.assessmentId,
        status
      });
    }

    await saveResults(this.props.assessmentId);

  } catch (error) {
    this.handleError(error);
  }

  };

  generateSession = async () => {
    try {
      this.setState({ perceptionLoading: true });
      let sessions = await calcularSomenteDataFim({
        consultor: this.props.consultantId,
        assessmentId: this.props.assessmentId
      });

      if (!sessions.length) return notifyError(Messages.Geral.msgOperationError);

      let sessoes = sessions;

      sessoes.forEach(el => {
        el.local = 'sem local - sessão gerada automaticamente';
        el.prazoInscricaoTipo = 1;
        el.prazoInscricaoAntecedencia = null;
        el.prazoInscricaoDataInicio = moment();
        el.prazoInscricaoDataFim = moment('2099-12-31');
      })

      await api
        .post("api/sessoes/multiplas", sessoes)
        .then((res) => {
          if (!res.data?.results?.length) return notifyError(Messages.Geral.msgOperationError);
          notifySuccess("Sessão gerada com sucesso!");
          sessoes[0]._id = res.data.results[0]._id;
        })
        .catch((err) => {
          this.handleError(err);
          return;
        });

      await api
        .put(`api/sessoes/${sessoes[0]._id}/vincular-participante`, {
          participante: this.props.participantId,
          assessment: localStorage.getItem("assessment")
        })
        .then(async () => {
          // notifySuccess("Participante vinculado com sucesso!");
        })
        .catch(err => this.handleError(err));

      const participantes = await this.obterSessoes();
      const foundParticipant = participantes.find(p => p.participantId === this.props.participantId)
      if (!foundParticipant) return this.setState({ noResultsFound: true });
      const participante = this.setParticipante(foundParticipant.id, participantes);
      this.obterPercepcao();
      ModalAcompanhamentoService.fetchHierarchy({
        projectId: sessionStorage.getItem('projeto'),
        cargo: participante.cargo
      })
      .then(hierarchies => {

        this.setState({ hierarchies });
        return this.getUserHierarchy(hierarchies);
      })
      .then(async _ => {
        try {
          if (participante && participante.participantId) {
            let response = await apiCalls.resultados.getInfoParticipante(
              sessionStorage.getItem("projeto"),
              participante.participantId
            );
            this.setState({ user: response.data });
          }
        } catch (err) {
          this.handleError(err);
        }
      })
      .catch(err => this.handleError(err));

      this.setState({ noResultsFound: false });
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ perceptionLoading: false });
    }
  }


  render() {
    if (this.state.perceptionLoading) return null;

    if (this.state.error) return (
      <div>
        <RedText>
          {Messages.Geral.msgDataFetchError}
        </RedText>
      </div>
    )

    if (this.state.noResultsFound) {
      return (
        <>
          <NoResultsFound />
        </>
      );
    }

    return (
      <div>
        <Modal isOpen={this.state.modalConfirmar} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Atenção!</ModalHeader>
          <ModalBody style={{ fontSize: 12 }}>
            Faltam {this.state.emptyFields} campos para serem preenchidos,
            deseja salvar assim mesmo?
          </ModalBody>
          <ModalFooter>
            <ButtonCancel
              onClick={() => this.setState({ modalConfirmar: false })}
            >
              Não
            </ButtonCancel>
            <ButtonSave
              type="button"
              onClick={() => {
                this.setState({ cienciaCamposVazios: true });
                setTimeout(async () => {
                  await this.handleSubmit();
                  this.props.setUpdateGraphs(true);
                }, 100);
              }}
            >
              Sim
            </ButtonSave>
          </ModalFooter>
        </Modal>
        <br />

        <p style={{ fontSize: 14, fontWeight: 'bold' }}>Preencha os dados abaixo</p>
        <br />
        <Subtitle>Atributos de Prontidão</Subtitle>
        <hr />
        <Row className="itensAlign">
          <Col sm="2">
            <Row className="toolTips2">
              <Span>Conhecimento Acumulado</Span>
            </Row>
            <Row style={{ marginLeft: 0 }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosProntidao
                    ? String(
                        this.state.percepcao.atributosProntidao
                          .conhecimentoAcumulado
                      )
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.conhecimentoAcumulado = value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.conhecimentoAcumulado = value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>

          <Col sm="2">
            <Row className="toolTips2">
              <Span>Nível de Entrega</Span>
            </Row>
            <Row style={{ padding: "0 13px" }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosProntidao
                    ? String(
                        this.state.percepcao.atributosProntidao.nivelEntrega
                      )
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.nivelEntrega = value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.nivelEntrega = value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>

          <Col sm="2">
            <Row className="toolTips2">
              <Span>Determinação</Span>
            </Row>
            <Row style={{ padding: "0 13px" }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosProntidao
                    ? String(
                        this.state.percepcao.atributosProntidao.determinacao
                      )
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.determinacao = value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.determinacao = value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>

          <Col sm="2">
            <Row className="toolTips2">
              <Span>Disposição</Span>
            </Row>
            <Row style={{ padding: "0 13px" }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosProntidao
                    ? String(this.state.percepcao.atributosProntidao.disposicao)
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.disposicao = value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.disposicao = value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>

          <Col sm="2">
            <Row className="toolTips2">
              <Span>Familiaridade com Níveis Superiores</Span>
            </Row>
            <Row style={{ padding: "0 13px" }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosProntidao
                    ? String(
                        this.state.percepcao.atributosProntidao
                          .familiaridadeNiveisSuperiores
                      )
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.familiaridadeNiveisSuperiores =
                    value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.familiaridadeNiveisSuperiores =
                    value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>

          <Col sm="2">
            <Row className="toolTips2">
              <Span>Protagonismo</Span>
            </Row>
            <Row style={{ marginRight: 0, padding: "0 13px" }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosProntidao
                    ? String(this.state.percepcao.atributosProntidao.prontidao)
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.prontidao = value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosProntidao.prontidao = value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>
        </Row>

        <br />
        <br />
        <Subtitle>Atributos de Potencial</Subtitle>
        <hr />
        <Row className="itensAlign">
          <Col sm="2">
            <Row className="toolTips2">
              <Span>Aptidão Cognitiva</Span>
            </Row>
            <Row style={{ marginLeft: 0 }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosPotencial
                    ? String(
                        this.state.percepcao.atributosPotencial
                          .estrategiasEnfrentamento
                      )
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.estrategiasEnfrentamento = value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.estrategiasEnfrentamento = value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>

          <Col sm="2">
            <Row className="toolTips2">
              <Span>Agenda de Mudança</Span>
            </Row>
            <Row style={{ padding: "0 13px" }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosPotencial
                    ? String(
                        this.state.percepcao.atributosPotencial.agendaMudanca
                      )
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.agendaMudanca = value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.agendaMudanca = value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>

          <Col sm="2">
            <Row className="toolTips2">
              <Span>Nível de Agregação de Valor</Span>
            </Row>
            <Row style={{ padding: "0 13px" }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosPotencial
                    ? String(
                        this.state.percepcao.atributosPotencial
                          .nivelAgregacaoValor
                      )
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.nivelAgregacaoValor = value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.nivelAgregacaoValor = value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>

          <Col sm="2">
            <Row className="toolTips2">
              <Span>Busca por Referências Profissionais</Span>
            </Row>
            <Row style={{ padding: "0 13px" }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosPotencial
                    ? String(
                        this.state.percepcao.atributosPotencial
                          .buscasReferenciasProfissionais
                      )
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.buscasReferenciasProfissionais =
                    value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.buscasReferenciasProfissionais =
                    value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>

          <Col sm="2">
            <Row className="toolTips2">
              <Span>Construção de Vínculos</Span>
            </Row>
            <Row style={{ padding: "0 13px" }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosPotencial
                    ? String(
                        this.state.percepcao.atributosPotencial
                          .construcaoVinculos
                      )
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.construcaoVinculos = value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.construcaoVinculos = value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>

          <Col sm="2">
            <Row className="toolTips2">
              <Span>Trânsito em Diferentes Níveis</Span>
            </Row>
            <Row style={{ marginRight: 0, padding: "0 13px" }}>
              <NumberFormat
                placeholder={"Valores entre 1 e 5"}
                style={{
                  border: "1px solid #e4e7ea",
                  width: "100%",
                  fontSize: 12,
                  padding: 2,
                  marginTop: 10
                }}
                tabIndex={5}
                value={
                  this.state.percepcao.atributosPotencial
                    ? String(
                        this.state.percepcao.atributosPotencial
                          .transitoDiferentesNiveis
                      )
                    : null
                }
                onBlur={(e) => {
                  let value = e.target.value.trim();
                  if (value.length === 2 && value.includes(".")) {
                    value = `${value}0`;
                    e.target.value = value;
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.transitoDiferentesNiveis = value;
                  this.setState({ percepcao });
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (e.target.value > 5) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  } else if (
                    e.target.value &&
                    e.target.value !== "." &&
                    Math.floor(Number(e.target.value)) <= 0
                  ) {
                    value = "";
                    e.target.value = "";
                    notifyError("Apenas valores entre 1 e 5 são permitidos");
                  }
                  let { percepcao } = this.state;
                  percepcao.atributosPotencial.transitoDiferentesNiveis = value;
                  this.setState({ percepcao });
                }}
                format={"#.#"}
              />
            </Row>
          </Col>
        </Row>

        <br />
        <br />
        <Subtitle>Preditivo</Subtitle>
        <hr />

        {this.state.cliente.map((dados) => {
          return (
            <div>
              <Row>
                <Col>
                  <p>
                    Pensando sobre o(a){" "}
                    {capitalizeFirstLetterEachWord(dados.nome)}{" "}
                    em relação ao potencial para assunção de posições futuras,
                    responda as questões abaixo:
                  </p>
                </Col>
              </Row>
            </div>
          );
        })}
        <br />

        {/* CARGO ATUAL */}
        <Row>
          <Col sm="12">
            <p>
              <Span>
                Considerando o cargo atual de{" "}
                {capitalizeFirstLetterEachWord(this.state.cargoAtual)}, você
                considera que ele está pronto para o nível requerido?
              </Span>
            </p>

            <div className="custom-radio">
              <input
                type="radio"
                id="custom1"
                name="radio1"
                onClick={(e) => {
                  let { percepcao } = this.state;
                  percepcao.preditivo.cargoAtual.prontoCargo = true;
                  percepcao.preditivo.cargoAtual.mesesPermanencia = "";
                  this.setState({
                    percepcao,
                    radioControl: false,
                    radioControlDois: false,
                  });
                }}
                checked={
                  this.state.percepcao.preditivo &&
                  this.state.percepcao.preditivo.cargoAtual.prontoCargo
                }
              />
              <label style={{ fontSize: 12 }} htmlFor="custom1">Pronto agora e adequado ao cargo</label>
            </div>
          </Col>
          <Col sm="12">
            <div className="custom-radio">
              <input
                type="radio"
                id="custom2"
                name="radio2"
                checked={
                  !this.state.percepcao.preditivo ||
                  !this.state.percepcao.preditivo.cargoAtual.prontoCargo
                }
                onClick={() => {
                  let { percepcao } = this.state;
                  percepcao.preditivo.cargoAtual.prontoCargo = false;
                  this.setState({
                    percepcao,
                    radioControl: true,
                    radioControlDois: true,
                    radioControlClick: false,
                  });
                }}
              />
              <label
                style={{ fontSize: 12 }}
                htmlFor="custom2"
                class="radioCampo"
                onClick={() => {
                  let { percepcao } = this.state;
                  percepcao.preditivo.cargoAtual.prontoCargo = false;
                  this.setState({
                    percepcao,
                    radioControl: true,
                    radioControlDois: true,
                    radioControlClick: false,
                  });
                }}
              >
                Quanto tempo precisaria para atender as expectativas para a
                função neste momento?
                <input
                  type="number"
                  class="mx-2 text-center"
                  value={
                    this.state.percepcao.preditivo
                      ? this.state.percepcao.preditivo.cargoAtual
                          .mesesPermanencia
                      : ""
                  }
                  disabled={
                    this.state.percepcao.preditivo &&
                    this.state.percepcao.preditivo.cargoAtual.prontoCargo
                  }
                  onChange={(e) => {
                    let { percepcao } = this.state;
                    percepcao.preditivo.cargoAtual.mesesPermanencia =
                      e.target.value;
                    this.setState({ percepcao });
                  }}
                  style={{ width: "40px" }}
                />
                meses
                {/* <span class="checkmark"></span> */}
              </label>
            </div>
          </Col>
        </Row>
        <br />

        <Row>
          <Col sm="12">
            <p>
              <Span>
              Considerando os desafios e demandas da posição de {capitalizeFirstLetterEachWord(this.state.nextCargo)} HOJE, qual a estimativa de tempo que o {capitalizeFirstLetterEachWord(this.state.participante && this.state.participante.nome ? this.state.participante.nome : '')} necessitaria para responder ao exigido para esta posição HOJE?
              </Span>
            </p>
          </Col>
          <Col sm="12">
            <NumberFormat
              class="mx-2 text-center"
              value={
                this.state.percepcao.preditivo
                  ? this.state.percepcao.preditivo.umCargoAcima.mesesPermanencia
                  : ""
              }
              onClick={(e) =>
                this.setState((prev) => ({ radioControl: !prev.radioControl }))
              }
              onChange={(e) => {
                let value = e.target.value;
                if (value && (Number(value) <= 0 || value.includes("-"))) {
                  notifyError("O tempo não pode ser inferior a 1 mês");
                  value = "";
                }
                let { percepcao } = this.state;
                percepcao.preditivo.umCargoAcima.mesesPermanencia = value;
                this.setState({ percepcao });
              }}
              style={{ width: "40px", fontSize: 12 }}
            />
            <Span>meses</Span>
          </Col>
        </Row>

        <br />

        <Subtitle>Histórico de Desempenho</Subtitle>
        <hr />

        <Row>
          <Col sm="4">
            <CustomSelect
              name={"template"}
              tabIndex={5}
              onChange={(e) => {
                let { percepcao } = this.state;
                percepcao.historicoDesempenho.desempenho =
                  this.state.optionsDesempenho[e.target.value].valor;
                this.setState({ percepcao });
              }}
              default="Desempenho"
              consultantSelected={
                (this.state.percepcao.historicoDesempenho.desempenho === null ||
                this.state.percepcao.historicoDesempenho.desempenho === undefined)
                ?
                  false
                :
                  true
              }
              options={this.state.optionsDesempenho.map((opt) => {
                return {
                  nome: opt.label,
                  selected:
                    this.state.percepcao.historicoDesempenho &&
                    this.state.percepcao.historicoDesempenho.desempenho ===
                      opt.valor,
                };
              })}
            />
          </Col>
          <Col sm="4">
            <CustomSelect
              name={"template"}
              tabIndex={5}
              default="Risco de Perda"
              onChange={(e) => {
                let { percepcao } = this.state;
                percepcao.historicoDesempenho.riscoPerda =
                  this.state.optionsRisco[e.target.value].valor;
                this.setState({ percepcao });
              }}
              consultantSelected={
                (this.state.percepcao.historicoDesempenho.riscoPerda === null ||
                  this.state.percepcao.historicoDesempenho.riscoPerda === undefined)
                  ?
                  false
                  :
                  true
              }
              options={this.state.optionsRisco.map((opt) => {
                return {
                  nome: opt.label,
                  selected:
                    this.state.percepcao.historicoDesempenho &&
                    this.state.percepcao.historicoDesempenho.riscoPerda ===
                      opt.valor,
                };
              })}
            />
          </Col>
        </Row>

        {/* {this.props.participantViewResults ? (
          <></>
        ) : (
          <Row>
            <Col>
              <BlueContainer>Comentários sobre o Participante</BlueContainer>
              <ErrorBoundary
                errorComponent={() => (
                  <h1>Não foi possível carregar comentários</h1>
                )}
              >
                <Comments
                  participantId={this.props.participante.participantId}
                  projectId={sessionStorage.getItem("projeto")}
                />
              </ErrorBoundary>
            </Col>
          </Row>
        )} */}

        <br />

        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <ButtonSave onClick={async () => {
            await this.handleSubmit();
            this.props.setUpdateGraphs(true);
          }}>
            Salvar
          </ButtonSave>
        </div>
      </div>
    );
  }
}
