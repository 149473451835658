import listCards from "./listCards";
import listParticipants from "./listParticipants";
import deleteResult from './deleteResult';
import calculateResult from './calculateResult';
import createResult from './createResult';
import changeName from './changeName';
import changeTableName from './changeTableName';

export async function getCards(projectId) {
  return await listCards(projectId);
}

export async function getParticipants(projectId, participants=null) {
  return await listParticipants(projectId, participants);
}

export async function removeResult(resultId) {
  return await deleteResult(resultId);
}

export async function getCalculatedResult(projectId, modules, participantsIds=null, unify, proofsToUnify, assessmentsToUnify, asyncsToUnify) {
  return await calculateResult(projectId, modules, participantsIds, unify, proofsToUnify, assessmentsToUnify, asyncsToUnify);
}

export async function postResult(payload) {
  return await createResult(payload);
}

export async function updateName(resultId, newName) {
  return await changeName(resultId, newName);
}

export async function updateTableName(resultId, newResultTable) {
  return await changeTableName(resultId, newResultTable);
}
