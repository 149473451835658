import api from "../Services/api";
import { assincronosApi } from "../Services/api";
import * as Constantes from '../Services/Constantes';
import { reportsApi } from "../Services/api";

const apiVersion = "v3"; // TODO: receber valor do arquivo .env

export default {
  buySimulation: {
    getItems: async function(userId) {
      return api.get(`/${apiVersion}/simula-compra?userId=${userId}`);
    },

    getOrdersList: async function({ userId, sortField, sortOrder, limit, page }) {
      return api.get(`/${apiVersion}/orders/${userId}`, {
        params: {
          sortField,
          sortOrder,
          limit,
          page
        }
      });
    }
  },
  testEnvironment: {
    massLinkParticipants: async function(envId, participantes) {
      return api.post(`/${apiVersion}/amb-testes/${envId}/mass-vincular-participantes`, {
        participantes
      });
    },

    massUnlinkParticipants: async function(envId, participantes) {
      return api.post(`/${apiVersion}/amb-testes/${envId}/mass-desvincular-participantes`, {
        participantes
      });
    },

    getList: async function() {
      return api.get(`/${apiVersion}/amb-testes/list-all`);
    },

    deleteEnvironment: async function(envId) {
      return api.post(`/${apiVersion}/amb-testes/${envId}/excluir-amb`);
    },

    getEnv: async function(envId) {
      return api.get(`/${apiVersion}/amb-testes/${envId}`);
    },

    getModules: async function() {
      return api.get(`/${apiVersion}/amb-testes/listar-modulos-dnd`);
    },

    getClients: async function() {
      return api.get(`/${apiVersion}/amb-testes/listar-clientes`);
    },

    getProjects: async function(clientId) {
      return api.get(`/${apiVersion}/amb-testes/listar-projetos?idCliente=${clientId}`);
    },

    getParticipants: async function(query) {
      return api.get(`/${apiVersion}/amb-testes/listar-participantes`, {
        params: query
      });
    },

    getAccessCode: async function() {
      return api.get(`/${apiVersion}/amb-testes/access-code`);
    },

    getIncludedParticipants: async function(query) {
      return api.get(`/${apiVersion}/amb-testes/listar-participantes-incluidos`, {
        params: query
      });
    },

    createEnv: async function({ configName, accessCode, participants, instruments }) {
      return api.post(`/${apiVersion}/amb-testes`, {
        config_name: configName,
        access_code: accessCode,
        participants,
        instruments
      });
    },

    updateEnv: async function(envId, { configName, accessCode, participants, instruments }) {
      return api.put(`/${apiVersion}/amb-testes/${envId}`, {
        config_name: configName,
        access_code: accessCode,
        participants,
        instruments
      });
    },

    includeParticipants: async function(envId, participants) {
      return api.post(`/${apiVersion}/amb-testes/${envId}/vincular-participantes`, {
        participantes: participants
      });
    },

    includeAllParticipants: async function(envId, { clientId, projectId, allClients, allProjects }) {
      return api.post(`/${apiVersion}/amb-testes/${envId}/vincular-all-participantes`, {
        clientId,
        projectId,
        allClients,
        allProjects
      });
    },

    deleteParticipants: async function(envId, participants) {
      return api.post(`/${apiVersion}/amb-testes/${envId}/excluir-participantes`, {
        participantes: participants
      });
    },

    deleteAllParticipants: async function(envId) {
      return api.post(`/${apiVersion}/amb-testes/${envId}/excluir-all-participantes`);
    },

    inviteEmail: async function(envId, { participants, sendToAll }) {
      return assincronosApi.post(`/schedule-email/amb-testes/invite-email`, {
        participants,
        sendToAll,
        idTestConfig: envId
      });
    },
  },
  audit: {
    getLogs: async function(query) {
      return api.get(`/${apiVersion}/get-filtered-logs`, {
        params: query
      });
    },

    generateXlsx: async function(query) {
      return api.get(`/${apiVersion}/get-filtered-logs/generate-xlsx`, {
        responseType: 'arraybuffer',
        params: query
      });
    },
  },
  userTokens: {
    validateToken(token) {
      return api.get(`/${apiVersion}/user-tokens/${token}/validate`);
    },

    invalidateToken(token) {
      return api.put(`/${apiVersion}/user-tokens/${token}/invalidate`);
    },
  },
  copy: {
    copyFileFromUrl: async function(fileName) {
      return api.get(`/${apiVersion}/copy-files`, {
        params: {
          fileName
        },
      });
    }
  },
  users: {
    verifyToken() {
      return api.get(`/verify-token`);
    },
    verifyPassword(userCpf, userSenha) {
      return api.get(`/v3/auto-register/verify-password?cpf=${userCpf}&senha=${userSenha}`);
    },
    verifyModulesAccess(userId) {
      return api.get(`/v3/users/${userId}/verify-modules-access`);
    },
    getPolicyData(userId) {
      return api.get(`/v3/users/${userId}/get-policy`);
    },
    createConsultant(payload) {
      return api.post(`/v3/auto-register/register-consultant`, payload);
    },
    updateConsultant(userId, payload) {
      return api.put(`/v3/users/${userId}/update-user-consultant`, payload);
    },
    getAllUnarchivedProjectUsersCpf(projectId) {
      return api.get(`/v3/users/get-unarchived-project-cpf-users/${projectId}`);
    },
    getUserByCpf(cpf) {
      return api.get(`/v3/auto-register/verify-cpf/${cpf}`);
    },
    getUserRegister(cpf) {
      return api.get(`/v3/users/${cpf}/get-user-register`);
    },
    acceptPolicy(userId) {
      return api.put(`/v3/users/${userId}/accept-policy`);
    },
    getContractData(userId) {
      return api.get(`/v3/users/${userId}/get-contract-data`);
    },
    verifyContract(userId) {
      return api.get(`/v3/users/${userId}/verify-contract`);
    },
    getAddress(userId) {
      return api.get(`/v3/auto-register/verify-address/${userId}`);
    },
    updateUserLoginAs(userId, loginAs) {
      return api.put(`/v3/users/login-as`, {
        userId,
        loginAs
      });
    },

    verifyUserFirstAccess(userCPF) {
      return api.get(`/v3/verify-user-first-access/${userCPF}`);
    },

    deleteUserProjectImg(userId, projectId) {
      return api.delete(`/v3/delete-images/user-project?userId=${userId}&projectId=${projectId}&propertyToDelete=foto`);
    },

    verifyIfUserIsConsultant(userId) {
      return api.get(`/v3/verify-user-consultant/${userId}`);
    },

    verifyUserEmail(email, userId='') {
      return api.get(`/api/user/email/verify-user-email?email=${email}&userId=${userId}`);
    },

    modifyUserPassword(token, newPassword) {
      return api.put(`/v3/users/modify-password`, {
        token,
        newPassword
      });
    },

    recoverPassword({ cpf, encryptedVersion }) {
      return assincronosApi.post('/usuarios/recover-user-password', {
        cpf,
        encryptedVersion
      });
    },

    recoverEmail({ cpf, nome, emailAtual, emailNovo, nascimento, telefone, motivo }) {
      return assincronosApi.post('/usuarios/email/recover-user-email', {
        cpf,
        nome,
        emailAtual,
        emailNovo,
        nascimento,
        telefone,
        motivo
      });
    },

    getUsersPhotos({ projectId, provaId }) {
      return api.get(`/v3/users/photos`, {
        params: {
          provaId
        }
      });
    },
    /**
     * @description traz campos do primeiro nível do document "users"
     */
    getUsers(cpfs) {
      return api.post(`${apiVersion}/users/get-users-by-query`, {
          cpfs,
      })
    },

    getUserProject: async function(userId, projectId) {
      return api.get(`/api/user/${userId}/projeto-user`, {
        params: {
          idProjeto: projectId
        }
      });
    },
    getMultipleUsersProjectByCPF: function (cpfs, projectId) {
      const cpfsJson = JSON.stringify(cpfs);

      return api.get(`/${apiVersion}/users/project`, {
        params: {
          projectId,
          cpfs: cpfsJson
        },
      });
    },

    getMultipleUsersProject: function (projectId, usersId, sortField, sortOrder, page, limit, userProjectName, additionalQueryParams) {
      return api.post(`/${apiVersion}/users/get-project-info`, {
        projectId,
        usersId,
        sortField,
        sortOrder,
        page,
        limit,
        userProjectName,
        ...additionalQueryParams
      });
    }
  },
  provas: {
    async genResultsXlsx({ proofId, projectId }) {
      return await api.post(`/${apiVersion}/provas/${proofId}/get-proof-results-report`, {
        provasIds: [proofId],
        idProjeto: projectId
      }, { responseType: 'arraybuffer' })
    },

    async generateNaoIniciadosXlsx(proofId) {
      return api.get(`/${apiVersion}/provas/${proofId}/generate-unfinished-participants-report`, {
        responseType: 'arraybuffer',
      })
    },

    async habilitarMensagens({ idParticipante, provaId }) {
      return api.post(`/${apiVersion}/provas/${provaId}/habilitar-mensagens`, {
        idParticipante
      });
    },

    async desabilitarMensagens({ idParticipante, provaId }) {
      return api.post(`/${apiVersion}/provas/${provaId}/desabilitar-mensagens`, {
        idParticipante
      });
    },

    async enviarEmailResultados({ provaId, projectId, participants }) {
      return await assincronosApi.post(`schedule-email/provas/${provaId}/release-results-email`, {
        projectId,
        participants
      });
    },

    async getProofParticipantsStatus({ proofId }) {
      return api.get(`/${apiVersion}/provas/${proofId}/participants-status`);
    },

    async getProofStatus({ proofId, projectId }) {
      return api.get(`/${apiVersion}/provas/${proofId}/status`, {
        params: {
          projectId
        }
      });
    },

    async getParticipantDashboardProvas({ provaParticipantsIds, projection, v2 }) {
      return api.post(`/${apiVersion}/provas/participant-dashboard`, {
        provaParticipantsIds,
        projection,
      }, {
        params: {
          v2
        }
      });
    },

    async toggleVisibleResults({ provaId, isVisible, projectId }) {
      return api.put(`/${apiVersion}/provas/${provaId}/toggle-visible-results?projectId=${projectId}`, {
        isVisible
      });
    },

    getProofResults({ provaId, idProjeto, provasIds }) {
      return api.post(`/${apiVersion}/provas/${provaId}/results2`, {

        idProjeto,
        provasIds
      });
    },

    calcProofResults({ provaId, idProjeto }) {
      return api.post(`/${apiVersion}/provas/${provaId}/results`, {
        idProjeto
      });
    },

    async getProvasWithProjection({ provaParticipantsIds, projection }) {
      return api.post(`/${apiVersion}/provas/projection`, {
        provaParticipantsIds,
        projection
      });
    },

    getTextResult({ provaId, userId}) {
      return api.get(`/v3/prova-text-results/get-by-user`, {
        params: {
          provaId,
          userId
        }
      });
    },

    getParticipantAutorizadoInfo({ provaId }) {
      return api.get(`/v3/provas/${provaId}/participants-arquivado`);
    },

    getParticipantsTempos({ provaId }) {
      return api.get(`/v3/provas/${provaId}/participants-tempos`);
    },

    getTemplateTotalTime({ provaId }) {
      return api.get(`/v3/provas/${provaId}/template-total-time`);
    },

    getParticipantsProgress({ provaId, projectId }) {
      return api.get(`/v3/provas/${provaId}/participants-progress`, {
        params: { projectId }
      });
    },

    getProjetoParticipante: async function(params, queryParams) {
      return await api.get(`/api/user/${params.idUser}/projeto-user`, {
        params: {
          idProjeto: queryParams.idProjeto
        }
      });
    },

    addTempoProva: async function(idProva, idProjeto, users) {
      return await api.put(
        `/api/prova/tempo-extra/${idProjeto}/${idProva}`,
        { users }
      )
    },

    excluirQuestao: async function(idParticipante, idProjeto, idProva, questao) {
      return await api.put(
        `api/user/${idParticipante}/projeto/${idProjeto}/prova/${idProva}/excluir-questao/${questao}`
      )
    },

    async getProvas(query) {
      return await api.get(`${apiVersion}/provas`, {
        params: {
          provasIds: query.provasIds
        }
      });
    },

    async toggleArchived(proofId) {
      return api.put(`/api/prova/${proofId}/action/togglearquivar`);
    }
  },

  dinamica: {
    async genResultsXlsx({ dynamicId, projectId }) {
      return await api.post(`/${apiVersion}/projetos/${projectId}/dinamicas/${dynamicId}/get-dynamic-results-report`, {
        dynamicId,
        idProjeto: projectId
      }, { responseType: 'arraybuffer' })
    },

    uploadCaseImage({ projectId, dynamicId, formData }) {
      return api.put(`/api/projects/${projectId}/modulos/dinamicas/${dynamicId}/case-images`, formData)
    },

    scheduleReleaseResultsEmail({ dynamicId, projectId }) {
      return assincronosApi.post(`/schedule-email/dinamicas/${dynamicId}/schedule-access-password-email`, {
        projectId
      });
    },

    vincularParticipantes: async (idsParticipantes, { params }) => {
      return await api.post(
        `/${apiVersion}/projetos/${params.idProjeto}/dinamicas/${params.idDinamica}/actions/vincular-participantes`,
        {
          participantes: idsParticipantes
        }
      );
    },

    linkAll: async ({ params }) => {
      return await api.post(
        `/${apiVersion}/projetos/${params.idProjeto}/dinamicas/${params.idDinamica}/actions/link-all`
      );
    },

    desvincularParticipantes: async (idsParticipantes, { params }, verificarAntes = false) => {
      return await api.post(
        `/${apiVersion}/projetos/${params.idProjeto}/dinamicas/${params.idDinamica}/actions/desvincular-participantes`,
        {
          participantes: idsParticipantes,
          verificarAntes
        }
      );
    },

    unlinkAll: async ({ params }) => {
      return await api.post(
        `/${apiVersion}/projetos/${params.idProjeto}/dinamicas/${params.idDinamica}/actions/unlink-all`,
        {
          verificarAntes: params.verificarAntes
        }
      );
    },

    getEstilosResults: async (params, queryParams) => {
      return await api.get(
        `/${apiVersion}/projetos/${params.idProjeto}/dinamicas/${params.idDinamica}/results/grafico-eficacia`,
        {
          params: {
            idUser: queryParams.idUser,
            indexRodada: queryParams.indexRodada
          }
        }
      );
    },

    /**
     * @param {string} projetoId
     * @param {string} dinamicaId
     * @param {string} participanteId
     * @param {string} mesa
     * @param {string} rodada
     * @param {string} instrumentoIds ids dos instrumentos que queremos os resultados separados por vírgula
     *
     */
    getInstrumentsResults: async (projetoId, dinamicaId, participanteId, mesa, rodada, instrumentoIds) => {
      return api.get(
        `api/projects/${projetoId}/info/${Constantes.moduloDinamicas}/${dinamicaId}/acompanhamento/${participanteId}/${mesa}`, {
            params: {
              rodada,
              instrumentoIds
            }
          }).then(res => {
            console.log("Resposta de getInstruments Results");
            console.log(res.data);
            return res;
          })
    },

    atribuirSecretariado: async function (idProjeto, idDinamica, idUser, idRodada, idGrupo) {
      return api.post(
        `/${apiVersion}/projetos/${idProjeto}/dinamicas/${idDinamica}/actions/atribuir-secretariado`,
        {
          idUser,
          idRodada,
          idGrupo
        });
    },
    // ESSA Aaqui
    getInfoAcompanhamento: async function(idProjeto, idDinamica) {
      return await api.get(
        `/${apiVersion}/projetos/${idProjeto}/dinamicas/${idDinamica}/acompanhamento`);
    },

    getResultadoDinamica: async function(idDinamica, idProjeto) {
      return await api.get(
        `/${apiVersion}/projetos/${idProjeto}/dinamicas/${idDinamica}/results/ranking-dinamica`
      );
    },

    getParticipantesInfo: async function(idProjeto, idDinamica) {
      return api.get(
        `/${apiVersion}/projetos/${idProjeto}/dinamicas/${idDinamica}/participantes`
      )
    },

    getDadosTelaParticipantes: async function(idProjeto, filter = {}, params = {}) {
      return api.get(
        `/${apiVersion}/projetos/${idProjeto}/participantes?filter=${JSON.stringify(filter)}`,
        {
          params
        }
      )
    },

    async updateRankingSociometria(projectId, dinamicaId, instrumentId, roundIndex) {
      return api
        .post(
          `/${apiVersion}/projetos/${projectId}/dinamicas/${dinamicaId}/results/calculate-sociometria?instrumentId=${instrumentId}&roundIndex=${roundIndex}`
        );
    },

    async updateRankingStorytelling(projectId, dinamicaId, instrumentId, roundIndex) {
      return api
        .post(
          `/${apiVersion}/projetos/${projectId}/dinamicas/${dinamicaId}/results/calculate-storytelling?instrumentId=${instrumentId}&roundIndex=${roundIndex}`
        );
    },

    async updateRankingEstilos(projectId, dinamicaId, instrumentId, roundIndex) {
      return api
        .post(
          `/${apiVersion}/projetos/${projectId}/dinamicas/${dinamicaId}/results/calculate-estilos?instrumentId=${instrumentId}&roundIndex=${roundIndex}
          `
        );
    },

    async updateRankingCase(projectId, dinamicaId, instrumentId, roundIndex) {
      return api
        .post(
          `/${apiVersion}/projetos/${projectId}/dinamicas/${dinamicaId}/results/calculate-case?instrumentId=${instrumentId}&roundIndex=${roundIndex}
          `
        );
    },

    async updateRankingDinamica(projectId, dinamicaId) {
      return api
        .post(`/${apiVersion}/projetos/${projectId}/dinamicas/${dinamicaId}/results/calculate-total`);
    },

    async updateParticipantWeight(projectId, dinamicId, roundIndex) {
      return api
        .post(`/${apiVersion}/projetos/${projectId}/dinamicas/${dinamicId}/results/calculate-weights?roundIndex=${roundIndex}`);
    },

    async getStorytellingTemplates() {
      return api
        .get(`/${apiVersion}/storytelling-templates`);
    },

    async getCaseTemplates() {
      return api
        .get(`/${apiVersion}/case-templates`);
    },

    async getCaseConfig(idCaseConfig) {
      return api
        .get(`/${apiVersion}/case-config/${idCaseConfig}`);
    },

    async stopActivitiesLastRound(idProject, idDynamic, roundIndex) {
      return api.post(
        `/api/projects/${
        idProject
        }/dinamicas/${
        idDynamic
        }/rodadas/${
          roundIndex
        }/actions/stopAllPreviousAtividades`);
    },

    async saveGroups(idProject, idDynamic, newGroups, roundIndex, method) {
      return api.put(`api/projects/addGrupos/${idProject}/${idDynamic}`, {
        grupos: newGroups,
        rodada: roundIndex,
        metodo: method
      });
    },

    async getMultipleCriteria(params) {
      return api.get(`/${apiVersion}/criterion`, {
        params
      });
    },

    async getParticipantsAnswers(projectId, dynamicId) {
      return api.get(`/${apiVersion}/projetos/${projectId}/dinamicas/${dynamicId}/participants-answers`);
    },

    async getDinamicas(criadoPor, queryParams) {
      return api.get(`/${apiVersion}/dinamicas`, {
        params: {
          criadoPor,
          ...queryParams
        }
      });
    },

    async getDinamicasWithProjection(dynamicParticipantsIds, projection) {
      return api.post(`/${apiVersion}/dinamicas/projection?arquivado=false`, {
        dynamicParticipantsIds,
        projection
      });
    },

    async getDynamicById(dynamicId) {
      return api.get(`/${apiVersion}/dinamicas/${dynamicId}`);
    },

    async calcStorytellingGeneralResult(projectId, dynamicId) {
      return api.post(`/${apiVersion}/projetos/${projectId}/dinamicas/${dynamicId}/results/calculate-storytelling-general-result`);
    },

    async calcEstilosGeneralResult(projectId, dynamicId) {
      return api.post(`/${apiVersion}/projetos/${projectId}/dinamicas/${dynamicId}/results/calculate-estilos-general-result`);
    },

    async calcSociometriaGeneralResult(projectId, dynamicId) {
      return api.post(`/${apiVersion}/projetos/${projectId}/dinamicas/${dynamicId}/results/calculate-sociometria-general-result`);
    },

    async calcCaseGeneralResult(projectId, dynamicId) {
      return api.post(`/${apiVersion}/projetos/${projectId}/dinamicas/${dynamicId}/results/calculate-case-general-result`);
    },

    async restoreDynamic(dynamicId) {
      return api.put(`${apiVersion}/dinamicas/${dynamicId}/restore`);
    }
  },

  assincronos: {
    async getGroupAnswerersResults({ userId, assincId, instrumentId, projectId }) {
      return await assincronosApi.get(`/respondente/${userId}/${assincId}/${instrumentId}/get-group-answerers-result?projectId=${projectId}`)
    },

    async getGroupAnswerers({ assincId, projectId }) {
      return await assincronosApi.get(`/respondente/get-group-answerers/${assincId}/${projectId}`);
    },
    async forceHvResults({ assincId, projectId }) {
      return await assincronosApi.post(`/grupo-instrumentos/${assincId}/assinc-results/force-calc-results?projectId=${projectId}`);
    },

    async getParticipantHvResults({ participantId, assincId }) {
      return await assincronosApi.get(`/grupo-instrumentos/${assincId}/assinc-results/get-users-data/${participantId}`);
    },

    async getParticipantEstilosLiderancaResults({ participantId, assincId, projectId, instrumentId }) {
      return await assincronosApi.get(`usuarios/${participantId}/grupo-instrumentos/${assincId}/get-estilos-lideranca?projectId=${projectId}&instrumentId=${instrumentId}`);
    },

    async getParticipantEstilosComunicacaoResults({ participantId, assincId, projectId, instrumentId }) {
      return await assincronosApi.get(`usuarios/${participantId}/grupo-instrumentos/${assincId}/get-estilos-comunicacao?projectId=${projectId}&instrumentId=${instrumentId}`);
    },

    async genXlsxAssinc({ assincId }) {
      return await assincronosApi.get(`/grupo-instrumentos/${assincId}/instrumentos/get-status-assinc-report`, { responseType: 'arraybuffer' });
    },

    async genResultsXlsx({ assincId }) {
      return await assincronosApi.get(`/grupo-instrumentos/${assincId}/instrumentos/get-analise-results-report`, { responseType: 'arraybuffer' })
    },

    // POST /schedule-email/assincronos/:assincId/release-results-email
    async sendReleaseResultEmail({ assincId, projectId }) {
      return await assincronosApi.post(`/schedule-email/assincronos/${assincId}/release-results-email`, {
        projectId
      });
    },

    async sendEmailToAnswerers({ sendToAll, answerersEmails, assincronoId, projectId, participantId, instrumentId, requestUserId, isPlayer }) {
      return await assincronosApi.post(`/schedule-email/assincronos/${assincronoId}/schedule-answerers-email`, {
        sendToAll,
        answerersEmails,
        participantId,
        projectId,
        instrumentId,
        requestUserId,
        isPlayer
      });
    },

    async setReminderEmail({ assincronoId, value }) {
      return await assincronosApi.post(`/grupo-instrumentos/${assincronoId}/set-reminder-email`, { value });
    },
    getAssincronos : async function(createdBy, queryParams) {
      return await assincronosApi.get(`/grupo-instrumentos/show`, {
        params: {
          createdBy,
          ...queryParams
        }
      })
    },
    saveTextResults({ userId, assincronoId, instrumentId, result }) {
      return api.post(`/v3/results/user/${userId}/assincrono/${assincronoId}/instrument/${instrumentId}/save-text-result`, {
        result
      });
    },

    getEstilosComunicacaoTexts: async function(userId, assyncId, instrumentId, answerers=false) {
      return await api.get(`/v3/results/user/${userId}/assincrono/${assyncId}/instrument/${instrumentId}/text-result`);
    },

    getEstilosComunicacaoTextsAnswerers: async function(userId, assyncId, instrumentId, answererId) {
      return await api.get(`/v3/results/user/${userId}/assincrono/${assyncId}/instrument/${instrumentId}/text-result?answerer=${answererId}`);
    },

    responderInstrumentoAssinc: async function(params, queryParams, resposta) {
      return await assincronosApi.put(
        `/usuarios/${params.idUser}/grupo-instrumentos/${params.idGrpInstrumentos}/respostas/${params.idInstrumento}`,
        {
          resposta,
          projetoID: queryParams.projetoID
        },
        {
          params: {
            finalizado: queryParams.finalizado // indica se a atividade foi finalizada
          }
        }
      );
    },

    toggleInstrumentoRespostasAccess: async function(params, queryParams) {
      return await assincronosApi.post(
        `/grupo-instrumentos/${params.idGrp}/instrumentos/${params.idInstrumento}/change-respostas-access`,
        {},
        {
          params: {
            toggleAccessTo: queryParams.toggleAccessTo
          }
        }
      );
    },

    sendMensagemConvite: async function(params, reqBody) {
      return await assincronosApi.post(
        `/grupo-instrumentos/${params.idGrp}/instrumentos/${params.idInstrumento}/send-mensagem-convite`,
        {
          nomeDestinatario: reqBody.nomeDestinatario,
          emailDestinatario: reqBody.emailDestinatario,
          idConsultor: reqBody.idConsultor,
          idParticipante: reqBody.idParticipante
        }
      );
    },

    requestEmailQueueCreation: async function(params, reqBody) {
      return await assincronosApi.post(
        `/grupo-instrumentos/${params.idGrp}/actions/create-email-queue`,
        {
          idConsultor: reqBody.idConsultor,
          idsParticipantes: reqBody.idsParticipantes,
          idInstrumento: reqBody.idInstrumento,
          sendToAll: reqBody.sendToAll,
        }
      );
    },

    baixarResultadosInstrumento: async function(params) {
      return await assincronosApi.post(
        `/grupo-instrumentos/${params.idGrp}/instrumentos/${params.idInstrumento}/reports`
      );
    },

    /**
     * @param {{ participantes: { nome: string, cpf: string, respondentes: []} [] }} json
     */
    respondentMassRegistry: async (userId, assincGroupId, instrumentId, json) => {
      return assincronosApi.put( `/usuarios/${userId}/grupo-instrumentos/${assincGroupId}/instrumentos/${instrumentId}/action/add-respondente-batch`,
        json
      )
    },

    /**
     * @param { string } idGrp
     * @param { string } idConsultor
     * @param {{ email: string, nome: string }[]} respondentes
     */
    addEmailToSchedule: async (idGrp, idConsultor, respondentes) => {
      return assincronosApi.post(`/grupo-instrumentos/${idGrp}/actions/create-email-queue-respondentes`, { respondentes, idConsultor })
    },

    /**
     * @param {string} idGrp
     */
    patchInstrumentsGroup: async (idGrp, grpInstrumentos) => {
      return assincronosApi.patch(`/grupo-instrumentos/${idGrp}`, grpInstrumentos);
    },

    unlinkParticipant(participantId, instrumentId, instrumentGroupId) {
      return assincronosApi
        .post(`/grupo-instrumentos/${instrumentGroupId}/instrumentos/${instrumentId}/unlink-participant`, {
          participantId
        });
    },

    getUnlinkedParticipants(projectId, assincId, grpInstrumentId) {
      return assincronosApi
        .get(`/list-archiveds`, {
          params: {
            assincId,
            projectId,
            grpInstrumentId
          }
        });
    },

    getStatusAssinc(projectId, assincronousId, instrumentId, consultantId) {
      return assincronosApi.get(`/assinc-instrument-status`, {
        params: {
          projectId,
          assincronousId,
          instrumentId,
          consultantId,
          grpId: assincronousId
        }
      })
    },

    restoreParticipantOnInstrument(participantId, instrumentId, grpId) {
      return assincronosApi.post(`/grupo-instrumentos/${grpId}/instrumentos/${instrumentId}/restore-participant`,{
        participantId
      })
    },
  },

  projetos: {

    async scheduleReleaseSessionsEmail({ projectId }) {
      return await assincronosApi.post(`/schedule-email/projects/${projectId}/release-results-email`);
    },

    updateLastProject({ moduleName, projectId }) {
      return api.put(`/api/projeto/${projectId}/update-last-access`, {
        moduleName
      });
    },
    getProjectById(projectId) {
      return api.get(`/${apiVersion}/projetos2/${projectId}`)
    },

    getProjectNameById(projectId) {
      return api.get(`/${apiVersion}/projetos2/${projectId}/project-name`)
    },

    removeParticipants: async (projectId, participantsCpfs) => {
      return api.put(`api/projects/${projectId}/remover/participantes`,{
        cpfParticipants: participantsCpfs
      })
    },
    archiveParticipants: async (projectId, participantsCpfs, arquivado = true) => {
      return api.put(`api/projects/${projectId}/setarquivado/participantes`, {
        cpfParticipants: participantsCpfs,
        value: arquivado
      })
    }
  },
  clientes: {
    getClientById: async (clientId) => {
      return api.get(`api/enterprise/find/${clientId}`);
    },

    deleteEnterpriseImg: async (enterpriseId) => {
      return api.delete(`/v3/delete-images/enterprise?enterpriseId=${enterpriseId}&propertyToDelete=logo`);
    }
  },

  email: {
    async sendUserPassword({ sendToAll = false, idsParticipantes = [], projectId }) {
      return assincronosApi.post('/schedule-email/schedule-access-password-email', {
        sendToAll,
        idsParticipantes,
        projectId
      });
    },
    async sendDynamicAccessCode({ idsParticipantes = [], projectId, accessCode, dynamicId }) {
      return assincronosApi.post('/schedule-email/send-dynamic-access-code', {
        accessCode,
        dynamicId,
        idsParticipantes,
        projectId
      });
    },
    async sendProofAccessCode({ idsParticipantes = [], projectId, accessCode, proofId }) {
      return assincronosApi.post('/schedule-email/send-proof-access-code', {
        accessCode,
        proofId,
        idsParticipantes,
        projectId
      });
    },
  },
  massRegistration: {

    async getUsersAttachmentDetails(csvData, projectId) {
      const { rows, cols } = csvData;

      return await api.post(`/${apiVersion}/mass-registration/users/attachment-details`, {
        rows,
        cols,
        projectId
      });
    },

    async registerUsersToProject(csvData, projectId) {
      const { rows, cols } = csvData;

      return api.post(`/${apiVersion}/mass-registration/users/register-to-project`, {
        rows,
        cols,
        projectId
      });
    }
  },
  clients: {
    async getProjectByUserId(criadoPor, limit, page, sortField, sortOrder, cliente, ativo, lastModified) {
      return api.get(`/v3/projetos2`, {
        params: {
          criadoPor,
          limit,
          page,
          sortField,
          sortOrder,
          cliente,
          ativo,
          lastModified,
        }
      })
    },

    async getProjectByUserIdDashboard(criadoPor, limit, page, sortField, sortOrder, ativo, lastModified, optimized) {
      return api.get(`/v3/projetos2`, {
        params: {
          criadoPor,
          limit,
          page,
          sortField,
          sortOrder,
          ativo,
          lastModified,
          optimized
        }
      })
    },

    async getProjectDashboard(criadoPor, ativo, lastModified) {
      return api.get(`/v3/projetos2/dashboard/featuredProjects`, {
        params: {
          criadoPor,
          ativo,
          lastModified,
        }
      })
    },

    // @todo substituir por risco de overfetching
    async getEnterprises() {
      return api.get("/api/enterprise/");
    }
  },

  resultados: {
    // GET /{version}/modalresultados/header/:userId/:projectId
    getInfoParticipante: async (projectId, participantId, participantViewResults = false) => {
      return api.get(`/${apiVersion}/modalresultados/header/${participantId}/${projectId}`, {
        params: {
          participantViewResults
        }
      });
    },

    // GET /{version}/modalresultados/header-participante/:userId
    getInfoAllProjectsParticipante: async (userId) => {
      return api.get(`/${apiVersion}/modalresultados/header-participante/${userId}`);
    },

    dinamicas : {
      getInfoDinamica: async (projectId, participantId, dinamicaId) => {
        return api.get(`api/projects/${projectId}/participants/${participantId}/dinamica/${dinamicaId}/info-dinamica`);
      },

      getInfoStoryTelling: async (userId, projectId, dinamicaId, indexRodada,instrumentId) => {
        return api.get(`/${apiVersion}/modalresultados/dinamica/resposta/storytelling/${userId}/${projectId}/${dinamicaId}/${indexRodada}`,
        {
          params: {
            instrumentId
          }
        }
        );
      },

      getInfoCase: async (userId, projectId, dinamicaId, indexRodada, instrumentId) => {
        return api.get(`/${apiVersion}/modalresultados/dinamica/resposta/case/${userId}/${projectId}/${dinamicaId}/${indexRodada}?instrumentId=${instrumentId}`);
      },

      getInfoSociometria: async (userId, projectId, dinamicaId, indexRodada, instrumentId) => {
        return api.get(`/${apiVersion}/modalresultados/dinamica/resposta/sociometry/${userId}/${projectId}/${dinamicaId}/${indexRodada}?instrumentId=${instrumentId}`);
      },

      getInfoEstilosDeLideranca: async (projectId, idUser, dinamicaId, indexRodada) => {
        return api.get(`/${apiVersion}/projetos/${projectId}/dinamicas/${dinamicaId}/results/grafico-eficacia`,
          {
            params: {
              idUser,
              indexRodada
            }
          }
        );
      },

      getResultCase: async (userId, dynamicId) => {
        return api.get(`/${apiVersion}/modalresultados/dinamica/resposta/case-general-result/${userId}/${dynamicId}`)
      },

      getResultStorytelling: async (userId, dynamicId) => {
        return api.get(`/${apiVersion}/modalresultados/dinamica/resposta/storytelling-general-result/${userId}/${dynamicId}`)
      },

      getResultSociometria: async (userId, dynamicId) => {
        return api.get(`/${apiVersion}/modalresultados/dinamica/resposta/sociometria-general-result/${userId}/${dynamicId}`)
      },

      generalResultByInstrument: async (userId, dynamicId, istrumentId) => {
        return api.get(`/${apiVersion}/modalresultados/dinamica/resposta/general-result-by-instrument/${userId}/${dynamicId}/${istrumentId}`);
      },

    },

    provas : {
      getInfoProva: async (projectId, participantId, provaId) => {
        return api.get(`/${apiVersion}/modalresultados/prova/resposta/${participantId}/${projectId}/${provaId}`);
      },
    },

    reports: {
      getInfoReport: async (projectId, participantId, reportId) => {
        return api.get(`/${apiVersion}/modalresultados/report/resposta/${participantId}/${projectId}/${reportId}/`);
      }
    },

    assincronos : {
      getInfoInstrumento: async (projectId, participantId, assincronoId, instrumentId) => {
        return api.get(`/${apiVersion}/modalresultados/assincrono/resposta/${participantId}/${projectId}/${assincronoId}/${instrumentId}/`);
      },

      getInfoInstrumentoAnswerer: async (projectId, participantId, assincronoId, instrumentId) => {
        return api.get(`/${apiVersion}/modalresultados/assincrono/verify-answerer-resposta/${participantId}/${projectId}/${assincronoId}/${instrumentId}/`);
      },


      getInfoInstrumentoAnswererTipos: async (projectId, participantId, assincronoId, instrumentId) => {
        return api.get(`/${apiVersion}/modalresultados/assincrono/verify-answerer-resposta-tipos/${participantId}/${projectId}/${assincronoId}/${instrumentId}/`);
      },

      getInfoRespondente: async (projectId, participantId, assincronoId, instrumentId, participantQuery) => {
        return api.get(`/${apiVersion}/modalresultados/assincrono/respondente-resposta/${participantId}/${projectId}/${assincronoId}/${instrumentId}/`,
        {
          params: {
            participantQuery
          }
        });
      },

      getTextoConsultor: async (dimensao) => {
        return api.get(
          `api/resultado/tipos-de-personalidade/texto-consultor/${dimensao}`,
        );
      },

    },
  },

  comments: {

    async getComments(consultantId, participantId, projectId) {
      return api.get(`${apiVersion}/comments`, {
        params: {
          consultantId,
          participantId,
          projectId
        }
      });
    },

    async saveComment(consultantId, participantId, projectId, text) {
      return api.post(`${apiVersion}/comments`, {
        consultantId,
        participantId,
        projectId,
        text
      });
    },

    async editComment(commentId, comment) {
      return api.put(`${apiVersion}/comments/${commentId}`, { comment });
    }
  },

  reports: {

    async removeCardFromReports(moduleId, apenasDesativar = false) {
      return reportsApi.put('/configs/remove-card-from-reports', {
        module_id: moduleId,
        apenasDesativar
      });
    },

    async scheduleResultsEmail({ reportId, reportName, configId, projectId }) {
      return assincronosApi.post(`/schedule-email/reports/${reportId}/release-results-email`, {
        reportName,
        configId,
        projectId
      });
    },

    async getConfig(configId) {
      return reportsApi.get(`/configs/${configId}`);
    },

    async getParticipants(configId, per_page_items = 100) {
      const params = new URLSearchParams({
        per_page_items: JSON.stringify(per_page_items)
      }).toString();

      return reportsApi.get(`/participants/${configId}?${params}`);
    },

    async getGroups(configId, per_page_items = 100) {
      return reportsApi.get(`/groups/all/${configId }`,{
        params: {
          per_page_items
        }
      });
    },

    async getGroup(id, per_page_items = 100) {
      return reportsApi.get(`/groups/${id}`,{
        params: {
          per_page_items
        }
      });
    },

    async updateGroup(groupId, group) {
      return reportsApi.put(`/groups/${groupId}`,
        {
          name: group.name,
          participants: group.participants,
        }
      );
    },

    async updateGroupName(groupId, group) {
      return reportsApi.put(`/groups/updateName/${groupId}`,
        {
          name: group.name,
        }
      );
    },

    async updateGroup(groupId, group) {
      return reportsApi.put(`/groups/${groupId}`,
        {
          name: group.name,
          participants: group.participants,
        }
      );
    },

    async createGroup(group) {
      return reportsApi.post('/groups', group);
    },

    async excludeGroup(groupId) {
      return reportsApi.delete(`/groups/${groupId}`);
    },

    async updateReportParticipants(reportId, groupParticipants) {
      return reportsApi.put(`/participants/${reportId}`, {
        _id: groupParticipants._id,
        configId: groupParticipants.configId,
        participants: groupParticipants.participants
      });
    },

    async createParticipants(reportId) {
      return reportsApi.post('/participants', {
        config_id: reportId,
        participants: []
      });
    },

    async linkParticipantsByCPF(reportId, cpfs, link) {
      const params = new URLSearchParams({
        cpfs: JSON.stringify(cpfs),
        link
      }).toString();

      return reportsApi.put(`/participants/${reportId}/byCPF?${params}`);
    },

    async linkAll(config_id, project_id) {
      return reportsApi.post(`/participants/${config_id}/link-all`, {
        project_id
      });
    },

    async unlinkAll(config_id, project_id) {
      return reportsApi.post(`/participants/${config_id}/unlink-all`, {
        project_id
      });
    },

    async getParticipantsByGroup(groupId, per_page_items, current_page) {
      return reportsApi.get(`/groups/${groupId}`, {
        params: {
          per_page_items,
          current_page
        }
      });
    },

    async getReportGroupById(groupId, {
      user_cpf,
      user_name,
      current_page,
      per_page_items
    }) {
      return api.get(`/v3/reports-groups/${groupId}`, {
        params: {
          user_cpf,
          user_name,
          current_page,
          per_page_items
        }
      });
    },

    async linkParticipantsToGroup(groupId, participants) {
      return reportsApi.post(`/groups/${groupId}/link`, {
        participants
      });
    },

    async linkAllParticipantsToGroup(groupId, project_id, config_id) {
      return reportsApi.post(`/groups/${groupId}/linkAll`, {
        project_id,
        config_id
      });
    },

    async unlinkParticipantsFromGroup(groupId, participants) {
      return reportsApi.post(`/groups/${groupId}/unlink`, {
        participants
      });
    },

    async linkParticipantsToConfig(groupId, participants) {
      return reportsApi.post(`/participants/${groupId}/link`, {
        participants
      });
    },

    async unlinkParticipantsFromConfig(groupId, participants) {
      return reportsApi.post(`/participants/${groupId}/unlink`, {
        participants
      });
    },

    async getProcesses(done) {
      return reportsApi.get(`/reports/getProcesses/${done}`);
    },

    async getReport(report_id) {
      return reportsApi.post(`/reports/getReport/${report_id}`,
      {
        user_id: sessionStorage.getItem('user')
      }
      );
    },

    async createReport(payload) {
      return reportsApi.post(`/reports/`, payload);
    },
  },

  results: {
    async deleteUnifiedProofPhrases(resultId) {
      return api.delete(`${apiVersion}/provas/delete-unified-proof-phrases`, {
        data: {
          idResultado: resultId
        }
      })
    },
    async calcUnifiedProofPhrases(projectId, resultId) {
      return api.post(`${apiVersion}/provas/calc-unified-proof-phrases`, {
        idProjeto: projectId,
        idResultado: resultId
      })
    },
  },

  rodadas: {

    getRounds(roundsIds) {
      return api.get(`${apiVersion}/rodadas`, {
        params: {
          roundsIds
        }
      })
    }
  },

  instrumentos: {

    getInstruments(instrumentsIds) {
      return api.get(`${apiVersion}/instrumentos`, {
        params: {
          instrumentsIds
        }
      })
    },
    getInstruments2(projectId) {
      return api.get(`${apiVersion}/instrumentos/dynamics-list`, {
        params: {
          projectId
        }
      })
    },
  },

  userAssincronousModuleAnswers: {

    async getUserAnswers(query) {
      return await assincronosApi.get('/user-assinc-resposta', {
        params: query
      });
    }
  },

  widgets: {

    /**
     *
     * @param {*} preferences
     *
     * ### preferences structure
     * - id_widget
     * - id_user
     * - module_ref
     * -
     * - x
     * - y
     * - w
     * - h
     *
     */
    createPreferences(id_widget, id_user, module_ref, screen_ref, x, y, w, h) {
      return api.post(`${apiVersion}/widgets-preferences/store`, {
        id_widget,
        id_user,
        module_ref,
        screen_ref,
        x,
        y,
        w,
        h
      })
    },

    /**
     *
     * @param {*} preferences
     *
     * ### preferences structure
     * - id_widget
     * - id_user
     * - module_ref
     * - screen_ref
     * - x
     * - y
     * - w
     * - h
     *
     */
    updatePreferences(id_widget, id_user, module_ref, screen_ref, x, y, w, h) {
      return api.put(`${apiVersion}/widgets-preferences/update`, {
        id_widget,
        id_user,
        module_ref,
        screen_ref,
        x,
        y,
        w,
        h
      })
    },


    /**
     *
     * @param {*} params
     *
     * ### params structure
     * - screen_ref
     * - module_ref
     * - id_widget
     * - id_user
     *
     */
    getPreferences(params) {
      return api.get(`${apiVersion}/widgets-preferences`, {
        params
      })
    },

  },

  notifications: {
    async getNotifications(userId) {
      return await api.get(`${apiVersion}/notifications`, {
        params: {
          userId
        }
      })
    },

    async updateNotification(id, fields) {
      return await api.put(`${apiVersion}/notifications/${id}`, {
        fields
      })
    },

    async updateManyNotifications(notifications_ids, fields) {
      return await api.put(`${apiVersion}/notifications/many`, {
        notifications_ids,
        fields
      })
    },
  },

  assessments: {

    async genResultsXlsx({ assessmentId, sortField, sortOrder }) {
      return await api.post(`/${apiVersion}/assessments/${assessmentId}/get-assessment-results-report`, {
        sortField,
        sortOrder
      }, { responseType: 'arraybuffer', params: {
        sortField,
        sortOrder
      } })
    },

    async releaseInscriptionsConsultant({ sessions }) {
      return api.put(`api/sessoes/liberar-inscricoes-consultor`, { sessoes: sessions });
    },

    async unauthorizeInscriptionsConsultant({ sessions }) {
      return api.put(`api/sessoes/unauthorize-inscriptions-consultant`, { sessoes: sessions });
    },

    async atualizarTamanhoFonte({ tamanho, assessmentId }) {
      // POST /v3/assessments/:assessmentId/grafico-prontidao-potencial/atualizar-tamanho-fonte
      return api.post(`${apiVersion}/assessments/${assessmentId}/grafico-prontidao-potencial/atualizar-tamanho-fonte`, {
        assessmentId,
        tamanho
      });
    },

    async resetarPosicoesGrafico({ assessmentId }) {
      // POST /v3/assessments/:assessmentId/grafico-prontidao-potencial/resetar-posicoes
      return api.post(`${apiVersion}/assessments/${assessmentId}/grafico-prontidao-potencial/resetar-posicoes`, {
        assessmentId,
      });
    },

    async encontrarGrafico({ assessmentId }) {
      // GET /v3/assessments/:assessmentId/grafico-prontidao-potencial
      return api.get(`${apiVersion}/assessments/${assessmentId}/grafico-prontidao-potencial`, {
        assessmentId,
      });
    },

    async gravarGrafico({ datasets, assessmentId }) {
      // PUT /v3/assessments/:assessmentId/grafico-prontidao-potencial
      return api.put(`${apiVersion}/assessments/${assessmentId}/grafico-prontidao-potencial`, {
        assessmentId,
        datasets,
      });
    },

    async feedbackTexts({ assessmentId, participantId, projectId }) {
      return api.get(`${apiVersion}/assessments/${assessmentId}/feedback-texts`, {
        params: {
          participantId,
          projectId
        }
      });
    },

    async getSessionsByConsultant({ consultantId }) {
      return await api.get(`/api/sessoes/by-consultant/${consultantId}`);
    },

    async scheduleParticipantSessionChangeEmail({ pendencies, participantId, assessmentId, consultantPendencies }) {
      return await assincronosApi.post(`/schedule-email/assessment/${assessmentId}/participant-session-change-email`, {
        pendencies,
        participantId,
        consultantPendencies
      });
    },

    async scheduleSessionChangeEmail({ pendencies, sendToAll, participantsIds, assessmentId, projectId, participantId }) {
      return await assincronosApi.post(`/schedule-email/assessment/${assessmentId}/session-change-email`, {
        pendencies,
        sendToAll,
        projectId,
        participantId,
        participantsIds
      });
    },

    async scheduleReleaseSessionsEmail({ assessmentId, projectId }) {
      return await assincronosApi.post(`/schedule-email/assessment/${assessmentId}/release-sessions-email`, {
        projectId
      });
    },

    async sendReleaseResultEmail({ assessmentId, projectId }) {
      return await assincronosApi.post(`schedule-email/assessment/${assessmentId}/release-results-email`, {
        projectId
      });
    },

    async setReminderEmail({ assessmentId, value }) {
      return await api.post(`/api/assessments/${assessmentId}/set-reminder-email`, { value });
    },

    async getAssessments(createdBy, queryParams) {
      return await api.get(`${apiVersion}/assessments`, {
        params: {
          createdBy,
          ...queryParams
        }
      })
    },

    async getAssessmentsByIds(ids) {
      return await api.get(`/api/assessments/get-by-ids`, {
        params: {
          ids
        }
      });
    },

    async getAssessmentResults(assessment_id, sortField, sortOrder) {
      return await api.get(`${apiVersion}/results/assessment-results/${assessment_id}/`, {
        params: {
          sortField,
          sortOrder
        }
      });
    },
    async calculatePerceptionResults(assessment_id) {
      return await api.post(`${apiVersion}/perception-results/calculate-perception-results/`, {
        assessment_id
      })
    },

    async getResults(assessmentId, abbr = false) {
      return api.get(`/v3/results/assessment-results/${assessmentId}`, { params: { abbr }});
    },

    async getSessions(assessmentId) {
      return api.get(`/api/sessoes`, {
        params: {
          idAssessment: assessmentId
        }
      })
    },

    async getPerceptions(assessmentId, userId) {
      return api.get(`/api/percepcoes`, {
        params: {
          assessment: assessmentId,
          userId
        }
      })
    },
  },

  estilosLideranca: {
    getResultTexts({ userId, assincronoId }) {
      return api.get(`/v3/estilos-lideranca-assinc-result/get-by-user`, {
        params: {
          userId,
          assincronoId
        }
      });
    },
  },

  tiposPersonalidade: {

    getResultTexts({ userId, assincronoId }) {
      return api.get(`/v3/tipos-personalidade-text-result/get-by-user`, {
        params: {
          userId,
          assincronoId
        }
      });
    },

    calcTextResult({ userId, assincronoId, result }) {
      return api.post(`/v3/tipos-personalidade-text-result/calc-result`, {
        result,
        userId,
        assincronoId
      });
    }
  },

  locusControle: {

    getResultTexts({ userId, assincronoId }) {
      return api.get(`/v3/locus-controle-text-result/get-by-user`, {
        params: {
          userId,
          assincronoId
        }
      });
    },

    calcTextResult({ userId, assincronoId }) {
      return api.post(`/v3/locus-controle-text-result/calc-result`, {
        userId,
        assincronoId
      });
    }
  },

  escutaAtiva: {

    getResultTexts({ userId, assincronoId }) {
      return api.get(`/v3/escuta-ativa-text-result/get-by-user`, {
        params: {
          userId,
          assincronoId
        }
      });
    },

    calcTextResult({ userId, assincronoId }) {
      return api.post(`/v3/escuta-ativa-text-result/calc-result`, {
        userId,
        assincronoId
      });
    }
  },

  autoEficacia: {

    getResultTexts({ userId, assincronoId }) {
      return api.get(`/v3/auto-eficacia-text-result/get-by-user`, {
        params: {
          userId,
          assincronoId
        }
      });
    },

    calcTextResult({ userId, assincronoId }) {
      return api.post(`/v3/auto-eficacia-text-result/calc-result`, {
        userId,
        assincronoId
      });
    }
  },

  credits: {
    getModuleBalance({ userId, projectId, moduleName }) {
      return api.get(`/v3/credits/module-credits/${userId}`, {
        params: {
          projectId,
          moduleName
        }
      });
    },
    getAllBalance({ userId }) {
      return api.get(`/v3/credits/all/${userId}`);
    },
    getResumeBalance({ userId, projectId }) {
      return api.get(`/v3/credits/resume/${userId}`, {
        params: {
          projectId
        }
      });
    },
    getUserBalance({ userId }) {
      return api.get(`/v3/credits/user-credits/${userId}`);
    }
  },
  logs: {
    createUserLogoutLog({ userCpf, userId, role }) {
      return api.post("/v3/logs/create-user-logout-log", {
        userCpf,
        userId,
        role
      });
    },
    createUserLoginLog({ userCpf, userId, role }) {
      return api.post("/v3/logs/create-user-login-log", {
        userCpf,
        userId,
        role
      });
    },
  }
};
